import React from 'react'
import StarBackground from "./StarBackground.js";

const StarList = () => {
    return (
        [...Array(25)].map((e, i) => <StarBackground key={i} />)
    )
}

export default StarList;
