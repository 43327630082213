import React, { Component } from "react";
import "../AppStyle.css";
import Modal from "../components/Modal.js";

export class CatDetail extends Component {
  constructor() {
    super();
    this.state = {
      show: false
    };
  }

  showModal = () => {
    this.setState({ show: true });
  };
  hideModal = () => {
    this.setState({ show: false });
  };

  render() {
    // get selected info, change image size app/web
    const list = this.props.cat.info.map(info =>
      info.type === "app" ? (
        <div key={info.id}>
          <h3>{info.details}</h3>
          <a target="blank" href={info.url}>
            <img
              className="details2"
              src={info.img1}
              alt="category-details"
            ></img>
          </a>
          <p>{info.about}</p>
        </div>
      ) : (
        <div key={info.id}>
          <h3>{info.details}</h3>
          <a target="blank" href={info.url}>
            <img
              className="details"
              src={info.img1}
              alt="category-detail"
            ></img>
          </a>
          <p>{info.about}</p>
        </div>
      )
    );
    return (
      <div>
        <Modal show={this.state.show} close={this.hideModal}>
          <ul>{list}</ul>
        </Modal>
        <div
          className="cat"
          onClick={this.showModal} style={{
            backgroundImage: `url(${this.props.cat.img})`
          }}>
          <h2 onClick={this.showModal}>{this.props.cat.title}</h2>

        </div>
      </div>
    );
  }
}

export default CatDetail;
