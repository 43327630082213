import React from "react";
import "../AppStyle.css";
import closeBtn from "../img/close.png";

const Modal = ({ close, show, children }) => {
  const displayM = show ? { width: "100%" } : { width: "0" };
  const displayMFade = show ? { maxHeight: "100%" } : { maxHeight: "0" };
  const displayC = show ? { display: "block" } : { display: "none" };

  return (
    <div className="modal" style={displayMFade}>
      <div className="modalFade" style={displayM}>
        <div className="modalC" style={displayC}>
          {children}
          <span className="close">
            <img src={closeBtn} onClick={close} alt="project-detail" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Modal;
