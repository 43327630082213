import React, { Component } from "react";
import "./AppStyle.css";
import LangList from "./components/LangList.js";
import CatList from "./components/CatList.js";
import imgAbout from "./img/imgAbout.jpg";
import StarList from "./components/StarList";

class App extends Component {
  constructor() {
    super();
    this.state = {
      langs: [],
      category: [],
      show: false,
      contact: false,
      projectsActive: false,
      aboutActive: false,
    };
  }

  showModal = () => {
    this.setState({ show: true });
  };

  showContact = () => {
    this.setState(prevState => ({
      contact: !prevState.contact
    }));
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  categoriesShow = () => {
    this.setState({ catShow: true });
  };

  componentDidMount() {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        this.setState({ projects: false });
        this.setState({ about: false });
      }
    }
  }

  render() {

    const ref = React.createRef();
    const ref2 = React.createRef();
    const titleRef = React.createRef();

    //nav scroll refs
    const handleClick = () => {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
      this.setState({ projects: true });
      this.setState({ about: false });
    }


    const handleClick2 = () => {
      ref2.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
      this.setState({ projects: false });
      this.setState({ about: true });
    }


    return (
      <div>
        {/* Star background */}
        <div className="stars"><StarList /></div>

        <div className="main">
          <header>
            {/* Title */}
            <div className="title">
              <h2 ref={titleRef} onClick={handleClick}>ISLA B {'>'}</h2>
            </div>
            <ul className="home">
              <li>Full Stack</li>
              <li>Software Development</li>
            </ul>

            {/* Navigation */}
            <nav >
              <ul>
                <li onClick={handleClick}>
                  <span className={this.state.projects ? "navBtn active_nav" : "navBtn"}
                  >Projects</span>
                </li>
                <li onClick={handleClick2}>
                  <span className={this.state.about ? "navBtn active_nav" : "navBtn"}>About</span>
                </li>
              </ul>
            </nav>
          </header>

          {/* Main boxes */}
          <div ref={ref} className="content">
            <CatList category={this.state.category} />
          </div>

          {/* Languages */}
          <div className="langs">
            <LangList langs={this.state.langs} />
          </div>

          {/* About */}
          <div ref={ref2} className="about">
            <img src={imgAbout} alt="headshot" />
            <div className="about_info">
              <h2>About</h2>
              <p>
                I'm a kiwi software developer and designer with experience working for Australasian companies. My training includes Level 6 Diploma of Software
                Development <small>(GPA 9)</small> and Bachelor of Design <small>(Honours)</small>.
              </p>
              <p>
                I design and code websites, applications, games including CMS, security, accessibility, animations, accounts, SEO, integrations with social
                media, marketing, APIs, databases and cloud services
              </p>
              <div className="contact_cont">
                {this.state.contact ?
                  <ul className="contact">
                    <a href="mailto:islabecker@gmail.com">islabecker@gmail.com</a>
                    <a href="https://www.linkedin.com/in/isla-becker-10199359/" target="_blank" rel="noopener noreferrer">
                      <li>LinkedIn</li>
                    </a>
                  </ul> : <button className="buttonMain" onClick={this.showContact}>
                    Contact
                  </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
