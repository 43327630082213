import React, { Component } from 'react';
import '../AppStyle.css';
import Lang from '../components/Lang.js';

class LangList extends Component {
  constructor() {
    super();
    this.state = {
      langs: [
        {
          id: 1,
          language: "HTML"
        },
        {
          id: 2,
          language: "CSS"
        },
        {
          id: 3,
          language: "JS"
        },
        {
          id: 4,
          language: "React"
        },
        {
          id: 5,
          language: "Java"
        },

        {
          id: 7,
          language: "Python"
        },
        {
          id: 8,
          language: "PHP"
        },
        {
          id: 9,
          language: "SQL"
        },
        {
          id: 10,
          language: "C#"
        }
      ],
      tools: [
        {
          id: 11,
          language: "Django"
        },
        {
          id: 12,
          language: "WP"
        },
        {
          id: 13,
          language: "AWS"
        },
      ]
    };
  }

  render() {
    return (
      <ul>
        <Lang langs={this.state.langs} />
        <Lang langs={this.state.tools} />
      </ul>
    );
  }

}


export default LangList;
